<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขบุคลากร</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="firstname"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="firstname"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>นามสกุล</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="lastname"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="lastname"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อ (ภาษาอังกฤษ)</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="firstnameEng"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="firstnameEng"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>นามสกุล (ภาษาอังกฤษ)</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="lastnameEng"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="lastnameEng"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>Email</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="email"
                                rules="required|email"
                            >
                                <v-text-field
                                    v-model="email"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>สาขาวิชา</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="school"
                                rules="required"
                            >
                                <v-select
                                    :items="schoolItems"
                                    v-model="school"
                                    item-text="school_name"
                                    item-value="id"
                                    :error-messages="errors"
                                    dense
                                    outlined
                                ></v-select>
                            </validation-provider>

                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>กลุ่ม User</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="group"
                                rules="required"
                            >
                                <v-select
                                    :items="groupItems"
                                    v-model="group"
                                    item-text="group_name"
                                    item-value="id"
                                    :error-messages="errors"
                                    dense
                                    outlined
                                ></v-select>
                            </validation-provider>

                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['staffId'],
    data: () => ({
        firstname: '',
        lastname: '',
        firstnameEng: '',
        lastnameEng: '',
        email: '',
        school: null,
        schoolItems: [],
        formChkErrors: false,
        groupItems: [],
        group: null
    }),
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getUserGroup()
      await this.getSchool()
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getUserGroup() {
            try {
                const res = (await Api().get("usergroup")).data;

                if (res.status == 'success') {
                    this.groupItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getSchool() {
            try {
                const res = (await Api().get("school")).data;

                if (res.status == 'success') {
                    this.schoolItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getData() {
          try {
            const res = (await Api().get('staff/' + this.staffId)).data;

            if (res.status == 'success') {
              this.firstname = res.data.firstname;
              this.lastname = res.data.lastname;
              this.firstnameEng = res.data.firstname_en;
              this.lastnameEng = res.data.lastname_en;
              this.email = res.data.email;
              this.school = res.data.school;
              this.group = res.data.user_group

            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                firstname: this.firstname,
                lastname: this.lastname,
                firstname_en: this.firstnameEng,
                lastname_en: this.lastnameEng,
                email: this.email,
                school: this.school,
                group: this.group
            }

            this.formChkErrors = false
            this.formChkErrors = this.$refs.form.validate()

            if(this.formChkErrors){
              let res = (await Api().put("staff/" + this.staffId, req)).data;

              if (res.status == 'success') {
                  this.$store.commit('setLoading', false)
                  await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                  this.$router.push('/staff')
              }else{
                  this.$store.commit('setLoading', false)
              }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>